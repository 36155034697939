import _extends from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["className", "ariaLabel", "children", "reviewsData", "birdAPIData", "birdeyeApiBusinessId", "showBirdApiData"];
var __jsx = React.createElement;
import React, { useState, useEffect, useRef } from 'react';
import Heading from '../../atoms/Heading';
import RichText from '../../atoms/RichText';
import styled from 'styled-components';
import { ReviewsStyles, ReviewCardStyles } from './Reviews.style';
import { imageRenditions, calculateCharLimit } from './config';
import ReviewCard from '../../molecules/ReviewCard';
import Carousel from '../../molecules/Carousel';
import CarouselArrow from '../../atoms/CarouselArrow';
import Para from '../../atoms/Para';
import Anchor from '../../atoms/Anchor';
import Star from '../../atoms/Star';
import { ReviewLabels } from '../../../constants/labels/en';
import Modal from './Modal';
var ReviewsSection = styled.section.withConfig({
  componentId: "sc-y0ptcc-0"
})(["", ";"], ReviewsStyles);
var ReviewCardBlock = styled.section.withConfig({
  componentId: "sc-y0ptcc-1"
})(["", ""], ReviewCardStyles);
var Reviews = function Reviews(_ref) {
  var className = _ref.className,
    ariaLabel = _ref.ariaLabel,
    children = _ref.children,
    reviewsData = _ref.reviewsData,
    birdAPIData = _ref.birdAPIData,
    birdeyeApiBusinessId = _ref.birdeyeApiBusinessId,
    showBirdApiData = _ref.showBirdApiData,
    others = _objectWithoutProperties(_ref, _excluded);
  var reviewRef = useRef(null);
  var _useState = useState(null),
    parsedBirdApiData = _useState[0],
    setParsedBirdApiData = _useState[1];
  var _useState2 = useState(false),
    showPopUp = _useState2[0],
    setShowPopUp = _useState2[1];
  var _useState3 = useState(true),
    expandReview = _useState3[0],
    setExpandReview = _useState3[1];
  var anchorLink = reviewsData && (reviewsData === null || reviewsData === void 0 ? void 0 : reviewsData.anchorLink) || '';
  var _useState4 = useState(typeof window !== 'undefined' && window.innerWidth && (calculateCharLimit(window.innerWidth) || 240)),
    charLimit = _useState4[0],
    setCharLimit = _useState4[1];
  useEffect(function () {
    var updateWindowDimensions = function updateWindowDimensions() {
      // const newWidth = window.innerWidth;
      // setwindowInnerWidth(newWidth);
      setCharLimit(typeof window !== 'undefined' && window.innerWidth && (calculateCharLimit(window.innerWidth) || 240));
    };
    updateWindowDimensions();
    window.addEventListener('resize', updateWindowDimensions);
    return function () {
      return window.removeEventListener('resize', updateWindowDimensions);
    };
  }, []);
  var imgRenditions = {};
  imgRenditions.imageRenditions = imageRenditions;
  var sectionHeadline = reviewsData.sectionHeadline,
    subHeadline = reviewsData.subHeadline,
    writeReviewCta = reviewsData.writeReviewCta,
    readMoreReviewsCta = reviewsData.readMoreReviewsCta,
    reviewCardCollection = reviewsData.reviewCardCollection;
  var carouselArrows = {
    prevArrow: __jsx(CarouselArrow, {
      type: "previous"
    }),
    nextArrow: __jsx(CarouselArrow, {
      type: "next"
    })
  };
  var cards = reviewCardCollection && reviewCardCollection.items ? reviewCardCollection.items : [];
  useEffect(function () {
    if ((parsedBirdApiData == null || parsedBirdApiData.length == 0) && birdAPIData && birdAPIData.length > 0) {
      var filteredBirdAPIData = birdAPIData.find(function (each) {
        return each.businessID == birdeyeApiBusinessId;
      });
      if (filteredBirdAPIData && filteredBirdAPIData.reviewsData && filteredBirdAPIData.reviewsData.length > 0) {
        setParsedBirdApiData(parseBirdApiData(filteredBirdAPIData.reviewsData));
      }
    }
  }, [birdAPIData]);
  useEffect(function () {
    setExpandReview(true);
  });
  var parseBirdApiData = function parseBirdApiData(data) {
    var parsedApiData = [];
    if (data && data.length > 0) {
      data.map(function (each) {
        var eachObject = {};
        if (each.comments && each.reviewer && each.reviewer.firstName && each.rating && each.rating === 5.0) {
          eachObject['comments'] = each.comments;
          eachObject['reviewer'] = each.reviewer;
          eachObject['rating'] = each.rating;
        }
        if (Object.keys(eachObject).length != 0 && parsedApiData.length < 10) {
          parsedApiData.push(eachObject);
        }
      });
    }
    return parsedApiData;
  };
  var ReviewCardAPI = function ReviewCardAPI(_ref2) {
    var eachReview = _ref2.eachReview,
      className = _ref2.className,
      expandReview = _ref2.expandReview,
      setExpandReview = _ref2.setExpandReview,
      charLimit = _ref2.charLimit;
    var reviewer = eachReview.reviewer,
      comments = eachReview.comments;
    var reviewCardRef = useRef(null);
    var _useState5 = useState(comments && comments.length > charLimit),
      readMoreDisplay = _useState5[0],
      setReadMoreDisplay = _useState5[1];
    var _useState6 = useState(true),
      isCurrentSlide = _useState6[0],
      setIsCurrentSlide = _useState6[1];
    var _useState7 = useState(false),
      expandReviewCard = _useState7[0],
      setExpandReviewCard = _useState7[1];
    var _useState8 = useState(readMoreDisplay ? comments.substring(0, charLimit).trim().concat('... ') : comments),
      parsedComments = _useState8[0],
      setParsedComments = _useState8[1];
    useEffect(function () {
      if (expandReview == false) {
        setExpandReviewCard(false);
      }
    }, [expandReview]);
    useEffect(function () {
      if (expandReviewCard) {
        setReadMoreDisplay(false);
      } else {
        setReadMoreDisplay(comments && comments.length > charLimit);
      }
    }, [expandReviewCard]);
    useEffect(function () {
      if (readMoreDisplay) {
        setParsedComments(comments.substring(0, charLimit).trim().concat('... '));
      } else {
        setParsedComments(comments);
      }
    }, [readMoreDisplay, charLimit]);
    useEffect(function () {
      var mainElement = reviewRef && reviewRef.current;
      var reviewCardElem = reviewCardRef && reviewCardRef.current;
      if (!expandReviewCard && mainElement.offsetHeight > 0 && reviewCardElem.offsetHeight > 0) {
        if (charLimit > 30 && reviewCardElem.offsetHeight > mainElement.offsetHeight) {
          setCharLimit(charLimit - 30);
        }
      }
      if (parsedComments.length > charLimit && expandReviewCard == true) {
        var _mainElement = reviewRef && reviewRef.current;
        var _reviewCardElem = reviewCardRef && reviewCardRef.current;
        if (_reviewCardElem && _mainElement && _mainElement.offsetHeight && _reviewCardElem.offsetHeight && _mainElement.offsetHeight < _reviewCardElem.offsetHeight) {
          _mainElement.style.cssText += "height: auto;";
        }
      }
    }, [parsedComments]);
    useEffect(function () {
      var timer;
      if (reviewRef !== null && reviewRef !== void 0 && reviewRef.current && reviewCardRef !== null && reviewCardRef !== void 0 && reviewCardRef.current) {
        var _mainElement$getBound, _reviewCardElem$getBo;
        var mainElement = reviewRef && reviewRef.current;
        var reviewCardElem = reviewCardRef && reviewCardRef.current;
        if ((mainElement === null || mainElement === void 0 ? void 0 : (_mainElement$getBound = mainElement.getBoundingClientRect()) === null || _mainElement$getBound === void 0 ? void 0 : _mainElement$getBound.width) > 0 && (reviewCardElem === null || reviewCardElem === void 0 ? void 0 : (_reviewCardElem$getBo = reviewCardElem.getBoundingClientRect()) === null || _reviewCardElem$getBo === void 0 ? void 0 : _reviewCardElem$getBo.width) > 0) {
          // makes all anchors in hero temporarely untabbable while slide is changing
          setIsCurrentSlide(false);
          // the dealy is to give time for slide to be, at least, over halfway done
          var delayInMilliseconds = 700;
          timer = setTimeout(function () {
            var mainBox = mainElement.getBoundingClientRect();
            var cardBox = reviewCardElem.getBoundingClientRect();
            // checks if the middle of the card is inside the element
            if ((cardBox.right + cardBox.left) / 2 < mainBox.left || (cardBox.right + cardBox.left) / 2 > mainBox.right) {
              setIsCurrentSlide(false);
            } else {
              setIsCurrentSlide(true);
            }
          }, delayInMilliseconds);
        }
      }
      return function () {
        clearTimeout(timer);
      };
    }, []);
    var ReadMore = function ReadMore() {
      if (!readMoreDisplay) {
        return null;
      }
      return __jsx("button", {
        title: "Read More",
        className: "".concat(className, " read-more-anchor"),
        tabIndex: isCurrentSlide ? 0 : -1,
        "aria-hidden": isCurrentSlide ? 'false' : 'true',
        onClick: function onClick() {
          setExpandReviewCard(true);
        }
      }, "[Read More]");
    };
    return __jsx(ReviewCardBlock, {
      className: "nva-review__card ".concat(className, " review-card-block").concat(expandReviewCard ? ' expand' : ''),
      ref: reviewCardRef
    }, __jsx("div", {
      className: "reviewer-block"
    }, __jsx("p", null, reviewer.firstName, " ", reviewer.lastName), __jsx(Star, {
      color: "#ddba29",
      height: "20px",
      width: "20px",
      count: 5
    }), __jsx("p", null, "On Google")), __jsx("div", {
      className: "review-comments"
    }, __jsx(Para, null, parsedComments, __jsx(ReadMore, null))));
  };
  var attributes = {};
  if (anchorLink != '') attributes.id = anchorLink;
  if (className) attributes.className = "".concat(className, " nva-review");
  return __jsx(ReviewsSection, attributes, __jsx("div", {
    className: "container"
  }, sectionHeadline && __jsx("div", {
    className: "col-xs-12 col-md-10 col-md-offset-1 nva-review__heading reviewHeading"
  }, __jsx(Heading, {
    HeadingType: "h2"
  }, sectionHeadline)), subHeadline && subHeadline.json && __jsx("div", {
    className: "col-xs-12 col-md-10 col-md-offset-1 nva-review__subheading reviewRichText"
  }, __jsx(RichText, {
    jsonContent: subHeadline.json
  })), showBirdApiData ? __jsx(React.Fragment, null, __jsx("div", {
    className: "nva-review__bird-cards review-bird",
    ref: reviewRef
  }, parsedBirdApiData && parsedBirdApiData.length > 0 ? __jsx(Carousel, _extends({
    className: "reviewcards",
    slidesToShow: 1,
    autoplay: false,
    autoplaySpeed: 5000,
    initialSlide: 0,
    dots: true,
    arrows: true,
    displayPlayPauseButton: false,
    beforeChange: function beforeChange() {
      setExpandReview(false);
      var mainElement = reviewRef && reviewRef.current;
      mainElement.style.cssText = "";
    }
  }, carouselArrows), parsedBirdApiData.map(function (eachReview, index) {
    return __jsx(ReviewCardAPI, {
      eachReview: eachReview,
      key: index,
      expandReview: expandReview,
      setExpandReview: setExpandReview,
      charLimit: charLimit
    });
  })) : null), __jsx("div", {
    className: "nva-review__anchors review_anchors"
  }, parsedBirdApiData && parsedBirdApiData.length > 0 && writeReviewCta && writeReviewCta.url && readMoreReviewsCta && readMoreReviewsCta.url ? __jsx(Para, null, __jsx(Anchor, _extends({
    title: writeReviewCta.label || ReviewLabels.WRITE_A_REVIEW,
    onClick: function onClick() {
      writeReviewCta.behavior == 'Open in a modal' && setShowPopUp(true);
    },
    to: writeReviewCta.behavior != 'Open in a modal' && writeReviewCta.url,
    styleType: "primary-anchor",
    className: "write_review",
    "data-analytics-value": writeReviewCta.dataAnalyticsValue,
    ctaBehavior: writeReviewCta.behavior != 'Open in a modal' && writeReviewCta.behavior,
    hyperlinkType: writeReviewCta.hyperlinkType,
    "aria-label": writeReviewCta.ariaLabel
  }, others), ReviewLabels.WRITE_A_REVIEW), __jsx(Anchor, _extends({
    title: readMoreReviewsCta.label || ReviewLabels.READ_MORE_REVIEWS,
    styleType: "primary-anchor",
    className: "read_reviews",
    "data-analytics-value": readMoreReviewsCta.dataAnalyticsValue,
    to: readMoreReviewsCta.url,
    hyperlinkType: readMoreReviewsCta.hyperlinkType,
    ctaBehavior: readMoreReviewsCta.behavior,
    "aria-label": readMoreReviewsCta.ariaLabel
  }, others), ReviewLabels.READ_MORE_REVIEWS), __jsx(Modal, {
    className: className,
    showPopUp: showPopUp,
    setShowPopUp: setShowPopUp,
    writeReviewCta: writeReviewCta
  })) : null)) : __jsx("div", {
    className: "review-wrapper"
  }, cards.map(function (card, index) {
    var reviewerName = card.reviewerName,
      reviewerImage = card.reviewerImage,
      linkToReview = card.linkToReview,
      reviewText = card.reviewText;
    return __jsx(ReviewCard, {
      className: "nva-review__card",
      review: reviewText && reviewText.json,
      reviewLinkUrl: linkToReview && linkToReview.url,
      reviewLinkHyperlinkType: linkToReview && linkToReview.hyperlinkType,
      reviewLinkText: linkToReview && linkToReview.label,
      reviewerImageUrl: reviewerImage ? reviewerImage.image && reviewerImage.image.file && reviewerImage.image.file.url || reviewerImage.image.url : '',
      reviewerImageAlt: reviewerImage ? reviewerImage.altText : '',
      reviewerImageTitle: reviewerImage ? reviewerImage.title || reviewerImage.altText : '',
      reviewerName: reviewerName,
      key: card.id,
      dataAnalyticsType: "link-reviews",
      dataAnalyticsValue: linkToReview && linkToReview.url,
      srcSetSetting: imgRenditions
    });
  }))));
};
Reviews.defaultProps = {
  className: '',
  ariaLabel: '',
  birdAPIData: [],
  birdeyeApiBusinessId: '',
  showBirdApiData: false
};
export default styled(Reviews).withConfig({
  componentId: "sc-y0ptcc-2"
})(["", ";"], ReviewsStyles);